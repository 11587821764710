import React, { useEffect, useState } from 'react';

const whatsapp = require('../src/images/whatsapp.png');
const Telegram = require('../src/images/tele.png');
const Signup = require('../src/images/signup.png');
const Bonus = require('../src/images/bonus.jpg');
const Kyc = require('../src/images/kyc.jpg');
const runny = require('../src/images/runny.jpg');
const Promotionloss = require('../src/images/promotionloss.jpg');
const Promotion = require('../src/images/promotion.jpg');
const Jugadu = require('../src/images/Jugadu.png');
const secure = require('../src/images/secure.png');

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 480); // Mobile view if width is less than or equal to 480px
    };

    handleResize(); // Set initial state
    window.addEventListener('resize', handleResize); // Add resize event listener

    return () => {
      window.removeEventListener('resize', handleResize); // Cleanup listener on unmount
    };
  }, []);

  const tabletViewStyle = {
    width: '768px', // Simulating tablet width
    margin: '0 auto', // Center the content on larger screens
    background: 'linear-gradient(to right, #4b6cb7, #182848)',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    overflow: 'hidden',
  };

  const mobileViewStyle = {
    width: '100%', // Use full width on mobile
    padding: '10px', // Add some padding
    background: 'linear-gradient(to right, #4b6cb7, #182848)', // Same background for mobile
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  };

  const headerStyle = {
    width: '100%',
    height: '60px',
    backgroundColor: '#2c3e50',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
    padding: '0 20px',
  };

  const logoStyle = {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
  };

  const bannerStyle = {
    width: '100%',
    height: 'auto',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    marginBottom: '20px',
    maxWidth: '100%',
  };

  const overlayStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    textAlign: 'center',
    color: 'white',
    padding: '20px',
  };

  const headingStyle = {
    fontSize: 'clamp(2rem, 5vw, 3rem)',
    marginBottom: '20px',
  };

  const paragraphStyle = {
    fontSize: 'clamp(1rem, 2.5vw, 1.5rem)',
    marginBottom: '40px',
  };

  const socialButtonsStyle = {
    display: 'flex',
    gap: '20px',
    flexWrap: 'wrap',
    justifyContent: 'center',
  };

  const buttonStyle = {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: '5px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    color: 'white',
    textDecoration: 'none',
    fontSize: 'clamp(0.8rem, 1.5vw, 1.2rem)',
    transition: 'background-color 0.3s ease',
  };

  const iconStyle = {
    width: '30px',
    marginRight: '10px',
  };

  const footerStyle = {
    width: '100%',
    padding: '20px',
    backgroundColor: '#2c3e50',
    color: 'white',
    textAlign: 'center',
    position: 'relative',
    bottom: 0,
    marginTop: '20px',
  };

  const handleMouseEnter = (e) => {
    e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.5)';
  };

  const handleMouseLeave = (e) => {
    e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.2)';
  };

  return (
    <div style={isMobile ? mobileViewStyle : tabletViewStyle}>
      {/* Header with circular image logo */}
      <header style={headerStyle}>
        <img src={Jugadu} alt="Logo" style={logoStyle} /> {/* Circular Logo */}
      </header>

      <div style={{ marginTop: '60px' }}> {/* Added marginTop to prevent content from being hidden behind the header */}
        <img src={Promotion} alt="Banner" style={bannerStyle} />
        <img src={Bonus} alt="Banner" style={bannerStyle} />

        <div style={overlayStyle}>
          <h1 style={headingStyle}>Enter the Jugadu Rummy</h1>
          <p style={paragraphStyle}>Join us & Get Your Rummy !D.</p>

          <div style={socialButtonsStyle}>
            <a
              href="https://t.me/ragmanindia"
              target="_blank"
              rel="noopener noreferrer"
              style={buttonStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img src={Telegram} alt="Telegram" style={iconStyle} />
              Telegram
            </a>

            <a
              href="https://wa.me/9105769851"
              target="_blank"
              rel="noopener noreferrer"
              style={buttonStyle}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <img src={whatsapp} alt="WhatsApp" style={iconStyle} />
              WhatsApp
            </a>
          </div>
        </div>

        <img src={secure} alt="Banner" style={bannerStyle} />
        <img src={Kyc} alt="Banner" style={bannerStyle} />

        {/* Footer with updated 18+ Disclaimer */}
        <footer style={footerStyle}>
          <p>
            Disclaimer: This website is not for users from Andhra Pradesh, Nagaland, Sikkim, Odisha, and Telangana. To play the Rummy Ares game, please ensure that you are at least 18 years old.
          </p>
        </footer>
      </div>
    </div>
  );
}

export default App;
